import React, { useRef, useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import Page from 'src/components/common/Page';
import InfiScroller from 'react-infi-scroller';
import Message from 'src/components/common/Message/Message';
import { useSuppliers } from 'src/hooks/queries/useSuppliers';
import OrderLoading from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { DEFAULT_ROWS_PER_PAGE, USER_TYPES } from 'src/constants/general.constants';
import { DEFAULT_SUPPLIERS } from 'src/constants/inventory.constants';
import { OrderLookupParams } from 'src/interfaces/IInventoryOrder';
import { useCustomRoles } from 'src/hooks/queries/useCustomRoles';
import { isEmpty } from 'lodash';
import { useInventoryOrdersInfinite } from '../../../hooks/queries/useInventoryOrders';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { OrderApprovalRow } from './OrderApprovalRow';
import Breadcrumb from '../common/Breadcrumb';
import { modalStyles } from './OrderApprovalRow/OrderApprovalRow.styles';
import OrderApprovalHeader from './OrderApprovalHeader';

const OrderApproval = () => {
  const refComponent = useRef<HTMLDivElement | null>(null);
  const classes = modalStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [filters, setFilters] = useState<OrderLookupParams>({});

  const { data: roles = [], isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers({ kind: DEFAULT_SUPPLIERS });
  const {
    results,
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
    hasNextPage,
    fetchNextPage,
    totalCount,
  } = useInventoryOrdersInfinite({ ...filters, limit: DEFAULT_ROWS_PER_PAGE, disabled: isEmpty(filters) });

  const isLoading = isLoadingSuppliers || isLoadingOrders || isLoadingRoles;
  const orders = results?.pages?.flatMap((page: any) => page?.data) || [];

  const handleLoadMore = () => {
    setScrollPosition(refComponent.current?.scrollTop || 0);
    fetchNextPage();
  };

  useEffect(() => {
    const scrollRef = refComponent?.current;
    if (scrollRef) {
      if (scrollRef.scroll) {
        scrollRef.scroll({ top: scrollPosition, behavior: 'smooth' });
      } else {
        scrollRef.scrollTop = scrollPosition;
      }
    }
  }, [scrollPosition]);

  const ResultsComponent =
    orders.length > 0 ? (
      <>
        {orders.map(
          (order) => !!order && <OrderApprovalRow inventoryOrder={order} suppliers={suppliers} key={order.id} />
        )}
        {hasNextPage && (
          <Box display="flex" justifyContent="center" alignItems="center" height={100}>
            {isFetchingOrders && <CircularProgress />}
          </Box>
        )}
      </>
    ) : (
      <Message text="No data to show" colSize={12} />
    );

  const loader = isLoadingOrders ? <ShortMultipleSkeleton key={0} /> : <OrderLoading />;

  return (
    <Page title="Order Approval">
      <Box width="100%" data-testid="order approval">
        <div ref={refComponent} className={classes.infiScrollContainer}>
          <Breadcrumb prependHeading="Inventory" />
          <OrderApprovalHeader
            isLoading={isLoading}
            setFilterState={setFilters}
            roles={roles}
            currentCount={totalCount}
          />
          <InfiScroller hasMore={hasNextPage} scrollTarget={refComponent.current} onLoadMore={handleLoadMore}>
            {isLoading ? loader : ResultsComponent}
          </InfiScroller>
        </div>
      </Box>
    </Page>
  );
};

export default OrderApproval;
