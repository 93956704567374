import React, { useState } from 'react';
import { Stepper, Step, StepLabel, StepContent, Modal, CardContent, Card, Button } from '@material-ui/core';
import classNames from 'classnames';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ICustomerStandingOrder } from 'src/interfaces/IStandingOrder';
import { ActiveStepWrapper } from '../../components/NewServiceVisit/ActiveStepWrapper';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { PatientHeader } from '../../components/ServiceVisitContainer/PatientHeader';
import { ServiceStep } from '../../components/ServiceVisitContainer/ServiceStep';
import { ServiceUnitStep } from '../../components/ServiceVisitContainer/ServiceUnitStep';
import { Footer } from '../../components/ServiceVisitContainer/Footer';
import { useStyles } from '../newServiceVisit.styles';
import { useServiceGroups, useServices } from '../../hooks/queries/services/useServices';
import { useSelectedServices } from '../../hooks/serviceVisit/useLineItems';
import { useCustomer } from '../../hooks/customer/usePatient';
import { HistoricalPhotosStep } from '../../components/ServiceVisitContainer/HistoricalPhotosStep';
import { ServiceVisitScannerStep } from '../../components/ServiceVisitContainer/ServiceVisitScannerStep';
import { useServiceVisitAssetsScanned } from '../../hooks/serviceVisit/useServiceVisitAssets';
import { dispatch } from '../../rematch';
import { useServiceVisitCheckout } from '../../hooks/serviceVisit/useCheckout';
import { CheckoutStep } from '../../components/ServiceVisitContainer/CheckoutStep';
import { getPendingServices } from '../../utils/serviceVisit/scanner.utils';
import {
  getInvalidStepId,
  getServiceGroupsPendingForConsents,
  getServiceVisitValidSteps,
} from '../../utils/serviceVisit/serviceVisit.utils';
import {
  AFTER_PHOTO_TYPE,
  BEFORE_PHOTO_TYPE,
  SERVICE_VISIT_STEPS,
} from '../../constants/serviceVisits/serviceVisit.constants';
import { useServiceVisitPhotos } from '../../hooks/serviceVisit/useServiceVisitPhotos';
import { BeforePhotosStep } from '../../components/ServiceVisitContainer/BeforePhotoStep';
import {
  isServiceVisitBeforePhotoRequired,
  isServiceVisitAfterPhotoRequired,
} from '../../utils/serviceVisit/photo.utils';
import { AfterPhotosStep } from '../../components/ServiceVisitContainer/AfterPhotosStep';
import { MedicalConsentStep } from '../../components/ServiceVisitContainer/MedicalConsentStep';
import { useGFEDiagnosisAcceptedServiceVisitMutation, useServiceVisit } from '../../hooks/serviceVisit/useServiceVisit';
import { usePractitioner } from '../../hooks/practitioner/usePractitioner';
import { useServiceVisitConsent } from '../../hooks/serviceVisit/useConsents';
import { PhotoConsentStep } from '../../components/ServiceVisitContainer/PhotoConsentStep';
import { useCustomerPhotoConsent } from '../../hooks/serviceVisit/useCustomerPhotoConsent';
import { useCustomerStandingOrders, useServiceVisitStandingOrders } from '../../hooks/serviceVisit/useStandingOrders';
import { StandingOrderStep } from '../../components/ServiceVisitContainer/StandingOrderStep';
import { ROUTES } from '../../constants/routes.constants';
import MedicalChartingStep from '../../components/ServiceVisitContainer/MedicalChartingStep/MedicalChartingStep';
import useMedicalCharting from '../../hooks/serviceVisit/useMedicalCharting';
import IServiceVisit from '../../interfaces/IServiceVisits';
import ServiceVisitNotes from '../../components/ServiceVisitContainer/ServiceVisitNotes';
import {
  READ_INVENTORY,
  READ_SERVICE_VISIT_OPTIONAL_AFTER_PHOTOS,
  READ_SERVICE_VISIT_PHOTO_CONSENT,
  CUSTOM_SERVICES,
  MEDICAL_DIRECTOR_NOTE_UPDATE,
} from '../../constants/actions.constants';
import { hasAccessTo } from '../../utils/auth.utils';
import {
  CLEARED_STATE,
  PHYSICIAN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  USER_TYPES,
} from '../../constants/general.constants';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { PHYSICIAN_PROVIDERS_PATH } from '../../routes/physicianRoutes';

/*
 * New Service visit version (From May 2023)
 * New component will keep the SOLID basis
 * Note: some of the sub-components are reused to keep the single responsibility.
 */

interface MatchParams {
  patientId: string;
  serviceVisitId: string;
}

const buttonStyles = {
  color: 'white',
  backgroundColor: '#12574d',
  margin: '0 3px',
  fontSize: '12px',
  paddingRight: '8px',
  paddingLeft: '8px',
};

const ServiceVisitContainer = ({
  match: {
    params: { patientId, serviceVisitId },
  },
}: RouteComponentProps<MatchParams>) => {
  const classes = useStyles();
  const history = useHistory();
  const [showErrors, setShowErrors] = useState(false);
  const [showGfeAlert, setShowGfeAlert] = useState(true);
  // Customer
  const { data: customer } = useCustomer(+patientId);

  // Service visit
  const { data: serviceVisitData, isLoading: isLoadingServiceVisit } = useServiceVisit(+serviceVisitId);

  const serviceVisit = serviceVisitData || ({} as IServiceVisit);
  // Services
  const {
    data: patientServices,
    isFetching: isFetchingServices,
    isLoading: isLoadingServices,
  } = useServices(+patientId, +serviceVisitId, !!serviceVisit.id);
  const { userType, permissions } = useSelector(({ auth }: any) => auth);
  const { data: serviceGroups, isFetching: isFetchingServiceGroups } = useServiceGroups(+patientId, +serviceVisitId);
  const {
    data: lineItems,
    selectedServiceIds,
    selectedServiceIdsWithCurrentUnits,
    isFetching: isFetchingSelectedServices,
    isLoading: isLoadingSelectedServices,
  } = useSelectedServices(+serviceVisitId);
  const isFetchingServicesStep = isFetchingServices || isFetchingServiceGroups || isFetchingSelectedServices;
  const services = patientServices.filter(({ id }) => selectedServiceIds.includes(id));
  const selectedServiceGroupIds = services.map(({ serviceGroupId }) => serviceGroupId);
  const selectedServiceGroupIdsWithCurrentUnits = services
    .filter(({ id }) => selectedServiceIdsWithCurrentUnits.includes(id))
    .map(({ serviceGroupId }) => serviceGroupId);

  const {
    data: serviceVisitAssets,
    isFetching: isFetchingServiceVisitAssets,
    isLoading: isLoadingServiceVisitAssets,
  } = useServiceVisitAssetsScanned(+serviceVisitId);
  const isFetchingServiceUnitStep = isFetchingServicesStep || isFetchingServiceVisitAssets;
  // TODO: obtain pending services from API
  const pendingServices = getPendingServices(services, lineItems, serviceVisitAssets);

  // TODO: add less priority to this request:
  const { data: checkout, isFetching: isFetchingCheckout, canUpdateAmounts } = useServiceVisitCheckout(+serviceVisitId);

  const isLoadingFooter = isFetchingServicesStep || isFetchingServiceUnitStep || isFetchingCheckout;

  // Low priority requests:
  const enableLowPriorityRequests =
    !!serviceVisit.id &&
    !isLoadingServices &&
    !isLoadingSelectedServices &&
    !isLoadingServiceVisitAssets &&
    !isLoadingServiceVisit;
  const { data: photos, isLoading: isLoadingPhotos } = useServiceVisitPhotos(
    +serviceVisitId,
    enableLowPriorityRequests
  );
  const serviceVisitPhotos = photos.filter(({ serviceVisitId: id }) => id === +serviceVisitId);
  const beforePhotos = serviceVisitPhotos.filter(({ photoType }) => photoType === BEFORE_PHOTO_TYPE);
  const afterPhotos = serviceVisitPhotos.filter(({ photoType }) => photoType === AFTER_PHOTO_TYPE);

  const { data: signedStandingOrders, isFetching: isFetchingSignedStandingOrders } = useServiceVisitStandingOrders(
    +serviceVisitId,
    enableLowPriorityRequests
  );
  const { data: customerStandingOrders, isFetching: isFetchingCustomerStandingOrders } = useCustomerStandingOrders(
    +serviceVisitId,
    enableLowPriorityRequests
  );

  const { data: medicalCharting, isFetching: isFetchingAnnotatedPhotos } = useMedicalCharting(+serviceVisitId);

  const selectedServiceGroups = serviceGroups.filter(({ id }) => selectedServiceGroupIds.includes(id)) || [];

  const customerStandingOrderIds = customerStandingOrders.map(
    ({ serviceGroupId }: ICustomerStandingOrder) => serviceGroupId
  );
  const servicesWithStandingOrders = services.filter(
    ({ hasStandingOrder, serviceGroupId }) =>
      hasStandingOrder &&
      selectedServiceGroupIdsWithCurrentUnits.includes(serviceGroupId) &&
      customerStandingOrderIds.includes(serviceGroupId)
  );

  const isBeforePhotoRequired = isServiceVisitBeforePhotoRequired(selectedServiceGroups, lineItems);
  const isAfterPhotoRequired = isServiceVisitAfterPhotoRequired(
    selectedServiceGroups,
    lineItems,
    hasAccessTo(READ_SERVICE_VISIT_OPTIONAL_AFTER_PHOTOS, permissions)
  );

  // @ts-ignore
  const { data: practitioner } = usePractitioner(serviceVisit.practitionerId, enableLowPriorityRequests);
  const { data: customerConsents } = useServiceVisitConsent(+serviceVisitId, enableLowPriorityRequests);
  const {
    data: customerPhotoConsent,
    refetch: refetchCustomerPhotoConsent,
    isLoading: isLoadingPhotoConsent,
    isFetched,
  } = useCustomerPhotoConsent(+patientId, +serviceVisitId, enableLowPriorityRequests);
  // TODO: Add in use memo and review variant items
  const serviceGroupPendingForConsents = getServiceGroupsPendingForConsents(selectedServiceGroups, services, lineItems);
  const updateGFEDiagnosisAcceptedMutation = useGFEDiagnosisAcceptedServiceVisitMutation(+serviceVisitId);
  const [saveMedicalCharting, setSaveMedicalCharting] = useState<boolean>(false);

  const servicesWithStandingOrdersRequired = servicesWithStandingOrders.filter(
    ({ serviceGroupId }) =>
      selectedServiceGroups.find(({ id }) => id === serviceGroupId)?.standingOrderRequiredForServiceVisit
  );

  // Valid steps vars TODO: avoid re-render
  const {
    isValidServiceVisit,
    isValidServiceUnits,
    isValidServiceVisitScannerStep,
    isValidBeforePhoto,
    isValidAfterPhoto,
    isValidCheckout,
    isValidStandingOrders,
    isValidServiceGroupConsent,
    isValidMedicalCharting,
  } = getServiceVisitValidSteps({
    services,
    lineItems,
    pendingServices,
    beforePhotos,
    afterPhotos,
    isBeforePhotoRequired,
    isAfterPhotoRequired,
    checkout,
    customerConsents,
    serviceGroupPendingForConsents,
    signedStandingOrders,
    servicesWithStandingOrders: servicesWithStandingOrdersRequired,
    medicalCharting,
    saveMedicalCharting,
  });

  const { data: roles = [] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });

  const roleName = roles.find((role) => +role.id === practitioner?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;

  const scrollToInvalidStep = (): void => {
    const step = getInvalidStepId({
      isValidServiceUnits,
      isValidServiceVisitScannerStep,
      isValidBeforePhoto,
      isValidAfterPhoto,
      isValidCheckout,
      isValidServiceGroupConsent,
      isValidStandingOrders,
      isValidMedicalCharting,
    });

    const element = document.getElementById(step);
    if (step && element) {
      setShowErrors(true);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const updateSelectedStep = (payload: number): void => {
    // TODO Create new version of ActiveStepWrapper
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload });
  };

  const navigateBack = () => {
    if (userType === PHYSICIAN_ROLE) {
      history.push(`${PHYSICIAN_PROVIDERS_PATH}/${serviceVisit.practitionerId}`);
    } else {
      history.push(ROUTES.PATIENT_ID(patientId));
    }
  };

  const isPhysician = userType === PHYSICIAN_ROLE;

  const checkDisabled = (serviceGroupdId: number) =>
    isFetchingServicesStep ||
    !canUpdateAmounts ||
    isPhysician ||
    (!(practitioner.whitelistedServiceGroups || []).includes(serviceGroupdId) &&
      !hasAccessTo(CUSTOM_SERVICES, permissions));

  const onAcceptGFEDiagnosisAlert = () => {
    updateGFEDiagnosisAcceptedMutation.mutate();
    setShowGfeAlert(false);
  };

  const medicalNoteAllowed = hasAccessTo(MEDICAL_DIRECTOR_NOTE_UPDATE, permissions) && isPhysician;

  const gfeDiagnosesAlert = () => {
    const showDialog =
      !isLoadingServiceVisit &&
      isNonLegacyPractitioner &&
      customer?.status &&
      customer?.status !== CLEARED_STATE &&
      showGfeAlert &&
      !serviceVisit.gfeDiagnosisAccepted;
    return (
      <Modal className={classes.modal} open={!!showDialog}>
        <Card>
          <CardContent className={classes.cardContent}>
            <h2>GFE Diagnosis Alert</h2>
            <p>There is no active GFE on file for this patient.</p>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <Button style={buttonStyles} onClick={onAcceptGFEDiagnosisAlert}>
              Proceed
            </Button>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </CardContent>
        </Card>
      </Modal>
    );
  };

  return (
    <main>
      <NavigationBar title="New Service Visit" onBackButtonClick={navigateBack} />

      <article>
        <div className={classes.header}>
          <PatientHeader
            patient={customer}
            serviceVisit={serviceVisit}
            serviceVisitId={+serviceVisitId}
            currentPatientId={+patientId}
            checkout={checkout}
            userType={userType}
          />
        </div>

        <div className={classes.stepsSection}>
          <Stepper orientation="vertical" className={classes.stepper} connector={<></>}>
            <Step active id={SERVICE_VISIT_STEPS.services}>
              <StepLabel className={classes.stepRoot} />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={1} onClick={() => updateSelectedStep(1)}>
                  <ServiceStep
                    services={patientServices}
                    serviceGroups={serviceGroups}
                    selectedServices={selectedServiceIds}
                    disabled={checkDisabled}
                    isLoading={isFetchingServiceGroups || isFetchingServices}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.serviceUnits}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidServiceUnits,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={2} onClick={() => updateSelectedStep(2)}>
                  <ServiceUnitStep
                    services={services}
                    lineItems={lineItems}
                    disabled={!canUpdateAmounts || isPhysician}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.photoHistory}>
              <StepLabel classes={{ root: classes.stepRoot }} />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={3} onClick={() => updateSelectedStep(3)}>
                  <HistoricalPhotosStep photos={photos} />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.beforePhotos}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidBeforePhoto,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={4} onClick={() => updateSelectedStep(4)}>
                  <BeforePhotosStep
                    opened={serviceVisit.opened && !isPhysician}
                    isPhotoRequired={isBeforePhotoRequired}
                    photos={beforePhotos}
                    isLoading={isLoadingPhotos}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.consents}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidServiceGroupConsent,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={5} onClick={() => updateSelectedStep(5)}>
                  <MedicalConsentStep
                    serviceGroupPendingForConsents={serviceGroupPendingForConsents}
                    patient={customer}
                    practitioner={practitioner}
                    customerConsents={customerConsents}
                    opened={serviceVisit.opened}
                    viewOnly={isPhysician}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.standingOrders}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidStandingOrders,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={6} onClick={() => updateSelectedStep(6)}>
                  <StandingOrderStep
                    customerStandingOrders={customerStandingOrders}
                    servicesWithStandingOrders={servicesWithStandingOrders}
                    signedStandingOrders={signedStandingOrders}
                    disabled={
                      isFetchingSignedStandingOrders ||
                      isFetchingCustomerStandingOrders ||
                      !serviceVisit.opened ||
                      isPhysician
                    }
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.afterPhotos}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidAfterPhoto,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={7} onClick={() => updateSelectedStep(7)}>
                  <AfterPhotosStep
                    opened={serviceVisit.opened && !isPhysician}
                    isPhotoRequired={isAfterPhotoRequired}
                    photos={afterPhotos}
                    isLoading={isLoadingPhotos}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            {hasAccessTo(READ_SERVICE_VISIT_PHOTO_CONSENT, permissions) && (
              <Step active id={SERVICE_VISIT_STEPS.photoConsent}>
                <StepLabel className={classNames(classes.stepRoot)} />
                <StepContent className={classes.stepContent}>
                  <ActiveStepWrapper step={8} onClick={() => updateSelectedStep(8)}>
                    <PhotoConsentStep
                      serviceVisitId={+serviceVisitId}
                      patientId={+patientId}
                      patient={customer}
                      serviceVisitOpen={serviceVisit.opened}
                      customerPhotoConsent={customerPhotoConsent}
                      loading={isLoadingPhotoConsent || !isFetched}
                      refetchCustomerPhotoConsent={refetchCustomerPhotoConsent}
                      practitioner={practitioner}
                      serviceGroups={serviceGroups}
                    />
                  </ActiveStepWrapper>
                </StepContent>
              </Step>
            )}
            {hasAccessTo(READ_INVENTORY, permissions) && (
              <Step active id={SERVICE_VISIT_STEPS.scanner}>
                <StepLabel
                  className={classNames(classes.stepRoot, {
                    [classes.stepIncompleteWithAlert]: showErrors && !isValidServiceVisitScannerStep,
                  })}
                />
                <StepContent className={classes.stepContent}>
                  <ActiveStepWrapper step={9} onClick={() => updateSelectedStep(9)}>
                    <ServiceVisitScannerStep
                      services={services}
                      pendingServices={pendingServices}
                      lineItems={lineItems}
                      serviceVisitAssets={serviceVisitAssets}
                      disabled={isFetchingServiceUnitStep || !canUpdateAmounts}
                    />
                  </ActiveStepWrapper>
                </StepContent>
              </Step>
            )}
            <Step active id={SERVICE_VISIT_STEPS.checkout}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidCheckout,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={10} onClick={() => updateSelectedStep(10)}>
                  <CheckoutStep
                    checkout={checkout}
                    disabled={isFetchingCheckout || !isValidServiceUnits || !checkout?.canPayCheckout || isPhysician}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
            <Step active id={SERVICE_VISIT_STEPS.medicalCharting}>
              <StepLabel
                className={classNames(classes.stepRoot, {
                  [classes.stepIncompleteWithAlert]: showErrors && !isValidMedicalCharting,
                })}
              />
              <StepContent className={classes.stepContent}>
                <ActiveStepWrapper step={11} onClick={() => updateSelectedStep(11)}>
                  <MedicalChartingStep
                    serviceVisit={serviceVisit}
                    patient={customer}
                    services={services}
                    lineItems={lineItems}
                    medicalCharting={medicalCharting}
                    patientPhotos={photos}
                    selectedServiceIds={selectedServiceIds}
                    serviceVisitId={+serviceVisitId}
                    isLoading={isFetchingServices || isFetchingAnnotatedPhotos || isLoadingPhotos}
                    saveMedicalCharting={saveMedicalCharting && !isPhysician}
                    setSaveMedicalCharting={setSaveMedicalCharting}
                    disabled={isPhysician}
                  />
                  <ServiceVisitNotes
                    serviceVisitId={+serviceVisitId}
                    serviceVisit={serviceVisit}
                    isPhysician={isPhysician}
                    canWriteMedicalNote={medicalNoteAllowed}
                    addMedicalNoteCb={() => history.goBack()}
                  />
                </ActiveStepWrapper>
              </StepContent>
            </Step>
          </Stepper>
        </div>
        {!isPhysician && (
          <Footer
            opened={serviceVisit.opened}
            isLoading={isLoadingFooter}
            isValid={isValidServiceVisit}
            scrollToInvalidStep={scrollToInvalidStep}
          />
        )}
        {gfeDiagnosesAlert()}
      </article>
    </main>
  );
};

export default ServiceVisitContainer;
