import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment, { ISO_8601 } from 'moment';
import { useHistory } from 'react-router-dom';
import {
  useMedspaLocationPractitioners,
  useUpdateMedspaLocationPractitionerCalendar,
  useUpdateMedspaLocationPractitioners,
} from 'src/hooks/queries/medspaAdmins/useMedspaLocationPractitioners';
// eslint-disable-next-line max-len
import { useUpsertMedspaAppointmentReminderConfiguration } from 'src/hooks/mutations/useUpsertAppointmentReminderConfiguration';
import { sortBy } from 'lodash';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { ONBOARDING_STEPS, SHOW_SUCCESS_MESSAGE } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import EHRTimePickerSelector, { TIME_PICKER_FORMAT } from 'src/components/ui/v1/EHRTimePickerSelector';
import { useMedspaPractitioners } from '../../../hooks/queries/usePractitioners';
import { IPractitioner } from '../../../interfaces/IPractitioner';
import { EHRCheckbox, EHRSelect, EHRSwitch, EHRTextField } from '../../ui/v1';
import { MultipleSkeleton } from '../../common/LoadingSkeleton';
import { SampleEmailMessage, SampleTextMessage } from '../../../pages/Configuration/Calendar/PatientNotification';
import { useStyles } from './index.styles';
import { INPUT_REGEX } from '../../../pages/Configuration/Calendar/schemas';
import { IMedspaLocationPractitioner } from '../../../interfaces/IMedspaLocationPractitioner';
import { upperCaseKeys } from '../../../utils/global';
import { dispatch } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { useMedspaLocations } from '../../../hooks/queries/medspaAdmins/useMedspaLocations';
import { MEDSPA_ADMIN_LOCATIONS, MEDSPA_ADMIN_PRACTITIONERS } from '../../../routes/medspaAdminRoutes';

type Props = { onSuccess?: () => void };

export const MedspaProviderAvailability = ({ onSuccess }: Props) => {
  const classes = useStyles();
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const history = useHistory();
  const [selectedPractitioner, setSelectedPractitioner] = useState<IPractitioner>();
  const {
    data: { medspaLocations = [] },
    isLoading: isLoadingMedspaLocations,
  } = useMedspaLocations();
  const fetchedMedspaLocations = !isLoadingMedspaLocations && medspaLocations.length > 0 && !!userGroupId;
  const { data: practitioners = [], isLoading } = useMedspaPractitioners(
    {},
    fetchedMedspaLocations,
    () => `/v4/medspa/${userGroupId}/practitioners`
  );
  const {
    data: { medspaLocationPractitioners = [] },
    isLoading: isLoadingLocations,
    refetch,
  } = useMedspaLocationPractitioners(selectedPractitioner?.id, !!selectedPractitioner?.id && fetchedMedspaLocations);

  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const { onboardingEnabled, allStepsCompleted } = useMedspaAdminOnboarding();

  const [selectedMedspaLocationPractitionerId, setSelectedMedspaLocationPractitionerId] = useState('' as string);

  const handlePractitionerChange = (value: string) => {
    setSelectedPractitioner(practitioners.find((practitioner: IPractitioner) => practitioner.id === +value));
    setSelectedMedspaLocationPractitionerId('');
  };

  useEffect(() => {
    if (!selectedPractitioner && practitioners.length > 0 && !isLoading) {
      setSelectedPractitioner(practitioners[0]);
    }
  }, [practitioners, selectedPractitioner, isLoading]);

  if (isLoading || isLoadingLocations || isLoadingMedspaLocations) {
    return <MultipleSkeleton addPosition={false} />;
  }

  if (medspaLocations.length === 0) {
    return (
      <Box className={classes.missingDataContainer}>
        <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
          <h3>Welcome to Your Provider Level Configuration Section!</h3>
          <p>
            It looks like you&apos;re almost ready to go! To proceed with setting up, we first need to configure a
            location. Set up your first location and take the next step in your calender setup journey!
          </p>
          <Button className={classes.saveButton} type="button" onClick={() => history.push(MEDSPA_ADMIN_LOCATIONS)}>
            Set up a location
          </Button>
        </Box>
      </Box>
    );
  }

  if (practitioners.length === 0) {
    return (
      <Box className={classes.missingDataContainer}>
        <Box style={{ textAlign: 'center', width: '50%', maxWidth: '500px' }}>
          <h4>Welcome to Your Provider Level Configuration Section!</h4>
          <p>
            It looks like you haven&apos;t added your first provider yet. To proceed with your calendar configuration,
            you&apos;ll need to add a provider, as the calendar is designed for patients to book appointments with
            providers at a location within your Medspa.
          </p>
          <Button className={classes.saveButton} type="button" onClick={() => history.push(MEDSPA_ADMIN_PRACTITIONERS)}>
            Add a Provider
          </Button>
        </Box>
      </Box>
    );
  }

  if (!selectedPractitioner) {
    return <MultipleSkeleton addPosition={false} />;
  }

  return (
    <>
      <Box width="100%">
        <Box style={{ marginTop: '2rem' }}>
          <EHRSelect
            label="Select Provider"
            dataCy="select-provider"
            onChange={handlePractitionerChange}
            id="providerSelect"
            value={selectedPractitioner.id}
            style={{ minWidth: '500px' }}
          >
            {practitioners.map((practitioner: IPractitioner) => (
              <MenuItem key={practitioner.id} value={practitioner.id}>
                {practitioner.firstName} {practitioner.lastName}
              </MenuItem>
            ))}
          </EHRSelect>
        </Box>
        <hr style={{ border: '0.5px solid lightgray', marginTop: '2rem', marginBottom: '2rem' }} />

        <Grid container spacing={2}>
          <Grid item xs={2} md={4}>
            <h4 style={{ margin: '0 0 0.25rem' }}>Working Locations</h4>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={9} md={7}>
            {sortBy(
              medspaLocationPractitioners,
              (medspaLocation: IMedspaLocationPractitioner) => medspaLocation.medspaName
            ).map((medspaLocation: IMedspaLocationPractitioner) => (
              <MedspaLocationCalendarCard
                medspaCalendar={medspaLocation}
                practitionerId={selectedPractitioner.id}
                onSuccessCallback={async () => {
                  if (onboardingEnabled && !allStepsCompleted) {
                    const resp = await updateProgress({
                      stepName: ONBOARDING_STEPS.CALENDAR_SET_UP_NAME,
                      percentage: 100,
                    });

                    if (resp.completed && resp?.message === SHOW_SUCCESS_MESSAGE) {
                      onSuccess?.();
                    }
                  }
                }}
              />
            ))}

            {medspaLocationPractitioners.length === 0 && (
              <Box>
                <h4 style={{ marginTop: 0 }}>No assigned locations.</h4>
                <Button
                  type="button"
                  onClick={() => history.push(MEDSPA_ADMIN_LOCATIONS)}
                  className={classes.saveButton}
                >
                  Add provider to location
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        <hr style={{ border: '0.5px solid lightgray', margin: '2rem 0' }} />

        <ReminderConfiguration
          refetchMedspaLocationPractitioners={refetch}
          selectedMedspaLocationPractitionerId={selectedMedspaLocationPractitionerId}
          setSelectedMedspaLocationPractitionerId={setSelectedMedspaLocationPractitionerId}
          medspaLocationPractitioners={medspaLocationPractitioners}
        />
      </Box>
    </>
  );
};

const initialDayAvailabilityMap = {
  Sunday: [],
  Monday: ['9:00am-5:00pm'],
  Tuesday: ['9:00am-5:00pm'],
  Wednesday: ['9:00am-5:00pm'],
  Thursday: ['9:00am-5:00pm'],
  Friday: ['9:00am-5:00pm'],
  Saturday: [],
};

const initialActiveDays = {
  Sunday: false,
  Monday: true,
  Tuesday: true,
  Wednesday: true,
  Thursday: true,
  Friday: true,
  Saturday: false,
};

const hourMFormat = 'hh:mma';
const hourFormat = 'HH:mm';

const DayAvailability = ({
  day,
  dayAvailabilityMap = {},
  setDayAvailabilityMap,
  editInput,
  setEditInput,
  editInputError,
}: {
  day: string;
  dayAvailabilityMap: Record<string, string[]>;
  setDayAvailabilityMap: (newMap: Record<string, string[]>) => void;
  editInput: string;
  setEditInput: (value: string) => void;
  editInputError: boolean;
}) => {
  const classes = useStyles();
  const [hourBlocks, setHourBlocks] = useState<Record<string, string>[]>([]);

  useEffect(() => {
    const timeRange = dayAvailabilityMap[day].length ? dayAvailabilityMap[day] : [];
    setHourBlocks(
      timeRange.map((period) => {
        const hourStart = moment(period?.split('-')[0], [ISO_8601, hourMFormat]).format(hourFormat).split(' ')[0];
        const hourEnd = moment(period?.split('-')[1], [ISO_8601, hourMFormat]).format(hourFormat).split(' ')[0];
        return {
          hourStart: hourStart?.length < 5 ? `0${hourStart}` : hourStart,
          hourEnd: hourEnd?.length < 5 ? `0${hourEnd}` : hourEnd,
        };
      })
    );
  }, [dayAvailabilityMap, day]);

  const addNewHourBlock = () => {
    const newDayAvailabilityMap = dayAvailabilityMap[day];
    const [currentHour, currentMinuteWithMeridiem] = hourBlocks.slice(-1)[0].hourEnd.split(':');
    const currentMinute = currentMinuteWithMeridiem.slice(0, 2);
    let startMeridiem = currentMinuteWithMeridiem.slice(2, 4)?.toLowerCase();
    let endMeridiem = currentMinuteWithMeridiem.slice(2, 4)?.toLowerCase();
    let newStartHour = +currentHour + 1;
    let newEndHour = +currentHour + 3;
    // eslint-disable-next-line max-len
    if (newStartHour >= 24) {
      if (newStartHour > 24) {
        newStartHour -= 24;
      }
      if (startMeridiem === 'am') {
        startMeridiem = 'pm';
      } else {
        startMeridiem = 'am';
      }
    }
    if (newEndHour >= 24) {
      if (newEndHour > 24) {
        newEndHour -= 24;
      }
      if (endMeridiem === 'am') {
        endMeridiem = 'pm';
      } else {
        endMeridiem = 'am';
      }
    }
    const newBlock = `${newStartHour}:${currentMinute}${startMeridiem}-${newEndHour}:${currentMinute}${endMeridiem}`;

    setDayAvailabilityMap({ ...dayAvailabilityMap, ...{ [day]: [...newDayAvailabilityMap, ...[newBlock]] } });
  };

  const removeLastHourBlock = () => {
    const newDayAvailabilityMap = dayAvailabilityMap[day];
    newDayAvailabilityMap.pop();
    setDayAvailabilityMap({ ...dayAvailabilityMap, ...{ [day]: newDayAvailabilityMap } });
  };

  const onChangeForm = (value: string, key: string, index: number) => {
    const newHourBlocks = hourBlocks;
    newHourBlocks[index] = { ...newHourBlocks[index], [key]: value };
    setHourBlocks(newHourBlocks);

    const newValue = `${moment(newHourBlocks[index].hourStart, [moment.ISO_8601, hourFormat]).format(
      hourMFormat
    )}-${moment(newHourBlocks[index].hourEnd, [moment.ISO_8601, hourFormat]).format(hourMFormat)}`;

    const valid = newValue.match(INPUT_REGEX);
    if (valid) {
      const newDayAvailabilityMap = [...dayAvailabilityMap[day]];
      newDayAvailabilityMap[index] = newValue;
      setDayAvailabilityMap({ ...dayAvailabilityMap, ...{ [day]: newDayAvailabilityMap } });
    }
  };

  return (
    <Box>
      {hourBlocks.map((hourBlock, index) => (
        <Box className={classes.dayAvailabilityRow} key={hourBlock.toString()}>
          <Box className={classes.dayAvailabilityDate}>{index === 0 ? day : null}</Box>
          <Box className={classes.subText}>from</Box>
          <Box className={classes.dayAvailabilityInput}>
            <EHRTimePickerSelector
              dataCy={`${day}-time-start-${index}`}
              value={moment(hourBlock.hourStart, hourFormat).format(TIME_PICKER_FORMAT)}
              onChange={(event: any) => {
                onChangeForm(moment(event?.target?.value, hourMFormat).format(hourFormat), 'hourStart', index);
              }}
              error={editInputError}
              edit={editInput === `${day}-time-start-${index}`}
              onEditInput={(value) => setEditInput(value ? `${day}-time-start-${index}` : '')}
            />
          </Box>
          <Box className={classes.subText}>to</Box>
          <Box className={classes.dayAvailabilityInput}>
            <EHRTimePickerSelector
              dataCy={`${day}-time-end-${index}`}
              value={moment(hourBlock.hourEnd, hourFormat).format(TIME_PICKER_FORMAT)}
              onChange={(event: any) =>
                onChangeForm(moment(event?.target?.value, hourMFormat).format(hourFormat), 'hourEnd', index)
              }
              error={editInputError}
              edit={editInput === `${day}-time-end-${index}`}
              onEditInput={(value) => setEditInput(value ? `${day}-time-end-${index}` : '')}
            />
          </Box>
          <Box className={classes.dayAvailabilityActions}>
            {index === hourBlocks.length - 1 && index !== 0 && (
              <Box>
                <Button className={classes.adjustTimeButton} onClick={removeLastHourBlock}>
                  &mdash;
                </Button>
              </Box>
            )}
            {index === hourBlocks.length - 1 && (
              <Box>
                <Button className={classes.adjustTimeButton} onClick={addNewHourBlock}>
                  +
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
const MedspaLocationCalendarCard = ({
  medspaCalendar,
  practitionerId,
  onSuccessCallback,
}: {
  medspaCalendar: IMedspaLocationPractitioner;
  practitionerId: number;
  onSuccessCallback?: () => void;
}) => {
  const classes = useStyles();
  const [editInput, setEditInput] = useState('');
  const [editInputError, setEditInputError] = useState(false);
  const [dayAvailabilityMap, setDayAvailabilityMap] = useState<Record<string, string[]>>(initialDayAvailabilityMap);
  const [activeDays, setActiveDays] = useState<Record<string, boolean>>(initialActiveDays);
  const [activeForScheduling, setActiveForScheduling] = useState<boolean>(false);
  const { mutateAsync: updateMedspaLocationCalendar, isLoading: isSavingCalendar } =
    useUpdateMedspaLocationPractitionerCalendar();
  const { mutateAsync: updateMedspaLocation } = useUpdateMedspaLocationPractitioners();

  useEffect(() => {
    setActiveForScheduling(medspaCalendar.active);
    const newMedpsaCalendar = upperCaseKeys(medspaCalendar.calendar || {});
    setDayAvailabilityMap(newMedpsaCalendar);
    if (Object.keys(newMedpsaCalendar)) {
      const newActiveDays = Object.keys(newMedpsaCalendar).reduce(
        (obj: Record<string, boolean>, currentValue: string) =>
          Object.assign(obj, {
            [currentValue]: newMedpsaCalendar[currentValue]?.length > 0,
          }),
        {}
      );
      setActiveDays(newActiveDays);
    }
  }, [medspaCalendar]);

  const handleChangeActiveForScheduling = async (active: boolean) => {
    await updateMedspaLocation({
      medspaLocationPractitioner: {
        activeForScheduling: active,
      },
      id: medspaCalendar.id,
    });
    setActiveForScheduling(active);
  };

  const changeActiveDay = (day: string) => {
    if (activeDays[day]) {
      setActiveDays({ ...activeDays, ...{ [day]: false } });
      setDayAvailabilityMap({ ...dayAvailabilityMap, ...{ [day]: [] } });
    } else {
      setActiveDays({ ...activeDays, ...{ [day]: true } });
      setDayAvailabilityMap({ ...dayAvailabilityMap, ...{ [day]: ['9:00am-5:00pm'] } });
    }
  };

  const saveMedspaCalendar = async () => {
    if (editInput !== '') {
      setEditInputError(true);
      return;
    }
    if (editInput === '' && editInputError) {
      setEditInputError(false);
    }

    await updateMedspaLocationCalendar({
      calendarId: medspaCalendar.calendarId,
      calendar: dayAvailabilityMap,
      practitionerId,
      medspaLocationId: medspaCalendar.medspaLocationId,
    });

    onSuccessCallback?.();
  };

  return (
    <Box>
      <Box>
        <h4 style={{ margin: '0 0 0.25rem' }}>{medspaCalendar.medspaName}</h4>
        <Box className={classes.subText}>
          {medspaCalendar.address}, {medspaCalendar.city}, {medspaCalendar.state}, {medspaCalendar.zipCode}
        </Box>
        <EHRCheckbox
          dataCy="activeForScheduling"
          label="Active For Scheduling"
          checked={activeForScheduling}
          onChange={(e) => handleChangeActiveForScheduling(e.target.checked)}
          className={classes.activeForSchedulingContainer}
        >
          <Typography variant="body2" className={classes.activeForSchedulingSubText}>
            If you select this option the provider will be available for booking within a calendar right away.
          </Typography>
        </EHRCheckbox>
      </Box>
      {activeForScheduling && (
        <>
          <Box>
            <Box className={classes.subTitle}>Select Working Days</Box>
            <Box className={classes.workingDaysButtonContainer}>
              <Button
                type="button"
                className={activeDays.Sunday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Sunday')}
              >
                S
              </Button>
              <Button
                type="button"
                className={activeDays.Monday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Monday')}
              >
                M
              </Button>
              <Button
                type="button"
                className={activeDays.Tuesday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Tuesday')}
              >
                T
              </Button>
              <Button
                type="button"
                className={activeDays.Wednesday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Wednesday')}
              >
                W
              </Button>
              <Button
                type="button"
                className={activeDays.Thursday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Thursday')}
              >
                T
              </Button>
              <Button
                type="button"
                className={activeDays.Friday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Friday')}
              >
                F
              </Button>
              <Button
                type="button"
                className={activeDays.Saturday ? classes.activeWorkingDay : classes.inactiveWorkingDay}
                onClick={() => changeActiveDay('Saturday')}
              >
                S
              </Button>
            </Box>
          </Box>
          <Box>
            <Box className={classes.subTitle}>Set Working Hours</Box>
            {Object.keys(activeDays).map((day) => {
              if (!activeDays[day]) {
                return null;
              }
              return (
                <DayAvailability
                  day={day}
                  dayAvailabilityMap={dayAvailabilityMap}
                  setDayAvailabilityMap={setDayAvailabilityMap}
                  key={day}
                  editInput={editInput}
                  setEditInput={setEditInput}
                  editInputError={editInputError}
                />
              );
            })}
            {Object.values(activeDays).every((active) => !active) && <p>No working day selected</p>}
          </Box>
          <Box className={classes.saveButtonContainer}>
            <Button
              className={classes.saveButton}
              type="button"
              onClick={saveMedspaCalendar}
              disabled={isSavingCalendar}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const ReminderConfiguration = ({
  refetchMedspaLocationPractitioners,
  selectedMedspaLocationPractitionerId,
  setSelectedMedspaLocationPractitionerId,
  medspaLocationPractitioners,
}: {
  refetchMedspaLocationPractitioners: any;
  selectedMedspaLocationPractitionerId: any;
  setSelectedMedspaLocationPractitionerId: any;
  medspaLocationPractitioners: any[];
}) => {
  const classes = useStyles();
  const { mutateAsync, isLoading: isSaving } = useUpsertMedspaAppointmentReminderConfiguration();
  const appointmentReminderConfigurations = medspaLocationPractitioners.flatMap(
    (mlp) => mlp.appointmentReminderConfigurations || []
  );
  const minuteOptions = ['15', '30', '45', '60', '75', '90', '105', '120'];
  const [smsConfig, setSmsConfig] = useState<any>(null);
  const [emailConfig, setEmailConfig] = useState<any>(null);

  useEffect(() => {
    if (medspaLocationPractitioners.length <= 1 && !selectedMedspaLocationPractitionerId) {
      const firstLocationId = medspaLocationPractitioners[0]?.id.toString();
      setSelectedMedspaLocationPractitionerId(firstLocationId);
    }
  }, [medspaLocationPractitioners, selectedMedspaLocationPractitionerId, setSelectedMedspaLocationPractitionerId]);

  useEffect(() => {
    if (selectedMedspaLocationPractitionerId) {
      const sms = appointmentReminderConfigurations.find(
        (config) =>
          config.type === 'SMSAppointmentReminderConfiguration' &&
          config.medspaLocationPractitionerId === +selectedMedspaLocationPractitionerId
      );
      const email = appointmentReminderConfigurations.find(
        (config) =>
          config.type === 'EmailAppointmentReminderConfiguration' &&
          config.medspaLocationPractitionerId === +selectedMedspaLocationPractitionerId
      );

      setSmsConfig(sms || {});
      setEmailConfig(email || {});
    }
  }, [selectedMedspaLocationPractitionerId]);

  const handleSettingsChange = (field: string, value: any, configType: string) => {
    if (configType === 'sms') {
      setSmsConfig((prevConfig: any) => ({ ...prevConfig, [field]: value }));
    } else if (configType === 'email') {
      setEmailConfig((prevConfig: any) => ({ ...prevConfig, [field]: value }));
    }
  };

  const submitSmsConfiguration = async () => {
    try {
      if (smsConfig) {
        await mutateAsync({
          id: smsConfig.id,
          data: {
            ...smsConfig,
            practitionerId: smsConfig.practitionerId,
            type: 'SMSAppointmentReminderConfiguration',
            medspaLocationPractitionerId: selectedMedspaLocationPractitionerId,
          },
        });
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'SMS notification settings',
              action: 'saved',
            }),
            type: 'success',
          },
        });
        await refetchMedspaLocationPractitioners();
      }
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'saving',
            element: 'SMS notification settings',
          }),
          type: 'error',
        },
      });
    }
  };

  const submitEmailConfiguration = async () => {
    try {
      if (emailConfig) {
        await mutateAsync({
          id: emailConfig.id,
          data: {
            ...emailConfig,
            practitionerId: emailConfig.practitionerId,
            type: 'EmailAppointmentReminderConfiguration',
            medspaLocationPractitionerId: selectedMedspaLocationPractitionerId,
          },
        });
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              element: 'Email notification settings',
              action: 'saved',
            }),
            type: 'success',
          },
        });
        await refetchMedspaLocationPractitioners();
      }
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'saving',
            element: 'Email notification settings',
          }),
          type: 'error',
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EHRSelect
          dataCy="locationChangeSelect"
          label="Select Location"
          value={selectedMedspaLocationPractitionerId || ''}
          onChange={(value: string) => setSelectedMedspaLocationPractitionerId(value)}
          displayEmpty
          style={{ minWidth: '300px' }}
        >
          <MenuItem value="" disabled>
            Select Location
          </MenuItem>
          {medspaLocationPractitioners.map((mlp) => (
            <MenuItem value={mlp.id} key={mlp.id}>
              {mlp.medspaName}
            </MenuItem>
          ))}
        </EHRSelect>
      </Grid>
      {selectedMedspaLocationPractitionerId && (
        <>
          {/* SMS Reminders Section */}
          <Grid item xs={2} md={4}>
            <h4 style={{ margin: '0 0 0.25rem' }}>Patient Notification</h4>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={9} md={7}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>SMS Reminders</h4>
              <EHRSwitch
                dataCy="sms-reminder-switch"
                checked={smsConfig?.enabled ?? false}
                onChange={(e) => handleSettingsChange('enabled', e.target.checked, 'sms')}
                color="primary"
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box style={{ display: 'inline-block', width: '48%' }}>
                {smsConfig?.valueTypeBeforeReminder === 'minutes' ? (
                  <EHRSelect
                    className={classes.timeConfigField}
                    label="Time"
                    dataCy="timeValue"
                    value={smsConfig?.valueBeforeReminder ?? ''}
                    onChange={(value: string) => handleSettingsChange('valueBeforeReminder', value, 'sms')}
                  >
                    {minuteOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                ) : (
                  <EHRTextField
                    className={classes.timeConfigField}
                    label="Time"
                    dataCy="timeValue"
                    value={smsConfig?.valueBeforeReminder ?? ''}
                    inputProps={{ step: 1 }}
                    type="number"
                    onChange={(e) => handleSettingsChange('valueBeforeReminder', e.target.value, 'sms')}
                  />
                )}
              </Box>
              <Box style={{ display: 'inline-block', width: '48%' }}>
                <EHRSelect
                  className={classes.timeConfigField}
                  label={<span>&nbsp;</span>}
                  dataCy="timeType"
                  value={smsConfig?.valueTypeBeforeReminder ?? 'minutes'}
                  onChange={(value: string) => handleSettingsChange('valueTypeBeforeReminder', value, 'sms')}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </EHRSelect>
              </Box>
            </Box>
            <Box style={{ marginTop: '2rem' }}>
              <EHRTextField
                className={classes.timeConfigField}
                label="Sms header"
                dataCy="email-header"
                value={smsConfig?.header ?? ''}
                type="text"
                onChange={(e) => handleSettingsChange('header', e.target.value, 'sms')}
              />
            </Box>
            <SampleTextMessage styles={{ background: 'white', width: '100%', marginBottom: '2rem' }} />
            <Box>
              <EHRTextField
                className={classes.timeConfigField}
                label="Sms Footer"
                dataCy="sms-footer"
                value={smsConfig?.footer ?? ''}
                type="text"
                onChange={(e) => handleSettingsChange('footer', e.target.value, 'sms')}
              />
            </Box>
            <Box className={classes.notificationSaveButtonContainer}>
              <Button
                className={classes.saveButton}
                type="button"
                onClick={submitSmsConfiguration}
                disabled={isSaving || !smsConfig?.valueBeforeReminder}
              >
                Save
              </Button>
            </Box>
            {/* Email Reminders Section */}
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4>Email Reminders</h4>
              <EHRSwitch
                checked={emailConfig?.enabled ?? false}
                onChange={(e) => handleSettingsChange('enabled', e.target.checked, 'email')}
                color="primary"
              />
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box style={{ display: 'inline-block', width: '48%' }}>
                {emailConfig?.valueTypeBeforeReminder === 'minutes' ? (
                  <EHRSelect
                    className={classes.timeConfigField}
                    label="Time"
                    dataCy="emailTimeValue"
                    value={emailConfig?.valueBeforeReminder ?? ''}
                    onChange={(value: string) => handleSettingsChange('valueBeforeReminder', value, 'email')}
                  >
                    {minuteOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                ) : (
                  <EHRTextField
                    className={classes.timeConfigField}
                    inputProps={{ step: 1 }}
                    label="Time"
                    type="number"
                    dataCy="emailTimeValue"
                    value={emailConfig?.valueBeforeReminder ?? ''}
                    onChange={(e) => handleSettingsChange('valueBeforeReminder', e.target.value, 'email')}
                  />
                )}
              </Box>
              <Box style={{ display: 'inline-block', width: '48%' }}>
                <EHRSelect
                  className={classes.timeConfigField}
                  label={<span>&nbsp;</span>}
                  dataCy="emailTimeType"
                  value={emailConfig?.valueTypeBeforeReminder ?? 'minutes'}
                  onChange={(value: string) => handleSettingsChange('valueTypeBeforeReminder', value, 'email')}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                  <MenuItem value="days">Days</MenuItem>
                </EHRSelect>
              </Box>
            </Box>
            <Box style={{ marginTop: '2rem' }}>
              <EHRTextField
                className={classes.timeConfigField}
                label="Email Header"
                dataCy="email-header"
                value={emailConfig?.header ?? ''}
                type="text"
                onChange={(e) => handleSettingsChange('header', e.target.value, 'email')}
              />
            </Box>
            <SampleEmailMessage styles={{ background: 'white', width: '100%', marginBottom: '2rem' }} />
            <Box>
              <EHRTextField
                className={classes.timeConfigField}
                label="Email Footer"
                dataCy="email-footer"
                value={emailConfig?.footer ?? ''}
                type="text"
                onChange={(e) => handleSettingsChange('footer', e.target.value, 'email')}
              />
            </Box>
            <Box className={classes.notificationSaveButtonContainer}>
              <Button
                className={classes.saveButton}
                type="button"
                onClick={submitEmailConfiguration}
                disabled={isSaving || !emailConfig?.valueBeforeReminder || !smsConfig?.valueBeforeReminder}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
