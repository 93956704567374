import Api from '../Api';

export interface ResourceFile {
  id: number;
  title: string;
  documentLink: string;
  category: number;
  section: string;
  documentType: number;
  new?: boolean;
}

export interface CreateResourceFilePayload {
  id?: number;
  title: string;
  category: number;
  section: string;
  documentLink: string;
  documentType: number;
}

export const ResourceFiles = {
  async get(category: number): Promise<ResourceFile[]> {
    const { success, data } = await Api.get(`/v4/resources_files?category=${category}`);

    return success ? data : [];
  },
  async create(data: CreateResourceFilePayload): Promise<{ success: boolean; data: ResourceFile }> {
    return Api.post('/v4/resources_files', data);
  },
  async update(id: number, data: CreateResourceFilePayload): Promise<{ success: boolean; data: ResourceFile }> {
    return Api.put(`/v4/resources_files/${id}`, data);
  },
  async delete(id: number): Promise<void> {
    return Api.delete(`/v4/resources_files/${id}`);
  },
};
