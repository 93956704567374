import { showSnackbar, useReactQueryOnErrorHandler } from 'src/utils/global';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MEDSPA_LOCATIONS, MEDSPA_LOCATION_SLUG, MEDSPA_LOCATION_USER_GROUP } from 'src/constants/reactQuery.keys';
import { MedspaLocation } from 'src/services/medspaAdmins/medspaAdmin';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import compile from '../../../utils/toastMessagesCompiler';

/* List of all Medspa Locations */
export const useMedspaLocations = (userId: string | number | null | undefined = '') => {
  const response = useQuery([MEDSPA_LOCATIONS, userId], () => MedspaLocation.get(), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'medspa locations' })
      ),
  });

  return {
    ...response,
    data: {
      medspaLocations: response.data?.medspaLocations || [],
      userGroup: response.data?.userGroup,
    },
  };
};

/* Create a new Medspa Location */
export const useCreateMedspaLocationMutation = (successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => MedspaLocation.create(params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_LOCATIONS]);
      showSnackbar(
        compile('generic.success_message', { element: 'Medspa Location', action: 'created' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

/* Update a Medspa Location */
export const useUpdateMedspaLocationMutation = (id: number, successCallback?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => MedspaLocation.update(id, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (!success) {
        showSnackbar(compile('generic.server_error'));
        return;
      }

      queryClient.invalidateQueries([MEDSPA_LOCATIONS]);
      showSnackbar(
        compile('generic.success_message', { element: 'Medspa Location', action: 'updated' }),
        SNACKBAR_SUCCESS
      );
      successCallback?.();
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useCheckMedspaLocationSlug = (slug: string, id?: number) => {
  const response = useQuery([MEDSPA_LOCATION_SLUG, slug, id], () => MedspaLocation.checkSlug(slug, id), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: !!slug,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'medspa location slug' })
      ),
  });

  return {
    ...response,
    data: response.data,
  };
};

export const useMedspaLocationByUserGroup = (userGroupId: number, enabled: boolean = true) => {
  const response = useQuery([MEDSPA_LOCATION_USER_GROUP, userGroupId], () => MedspaLocation.byUserGroup(userGroupId), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'medspa location by user group' })
      ),
    enabled,
  });

  return {
    ...response,
    data: response.data,
  };
};

export const useMedspaLocationsByRole = (userId: string | number | null | undefined = '', enabled: boolean = true) => {
  const response = useQuery([MEDSPA_LOCATIONS, userId], () => MedspaLocation.get(), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'medspa locations by role' })
      ),
  });

  return {
    ...response,
    data: {
      medspaLocations: response.data?.medspaLocations || [],
      userGroup: response.data?.userGroup,
    },
  };
};
