import { IAdCampaign, IAdCampaignLookup } from 'src/interfaces/IAdCampaign';
import Api from './Api';
import API_ROUTES from '../constants/apiRoutes.constants';

export type AdCampaignResponse<T = IAdCampaign> = {
  data?: T;
  errors?: string;
};

const AdCampaign = {
  async index(params: IAdCampaignLookup): Promise<IAdCampaign[] | undefined> {
    const { data } = (await Api.get(API_ROUTES.AD_CAMPAIGNS(), params)) as AdCampaignResponse<IAdCampaign[]>;
    return data;
  },

  async create(params: Partial<IAdCampaign>): Promise<IAdCampaign | undefined> {
    const { data } = (await Api.post(API_ROUTES.AD_CAMPAIGNS(), params)) as AdCampaignResponse;
    return data;
  },

  async update(params: Omit<Partial<IAdCampaign>, 'id'> & { id: number }): Promise<IAdCampaign | undefined> {
    const { data } = (await Api.put(API_ROUTES.AD_CAMPAIGNS(params.id), params)) as AdCampaignResponse;
    return data;
  },
};

export { AdCampaign, AdCampaign as default };
