import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Chip,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import IconCross from 'src/components/common/IconCross';
import { ExpandIconParams } from 'src/interfaces/IClientLead';
import useAdCampaigns, { useCreateAdCampaign } from 'src/hooks/queries/useAdCampaigns';
import { Build } from '@material-ui/icons';
import { IAdCampaign, ICreateCampaignProps, ICampaignRowProps } from 'src/interfaces/IAdCampaign';
import { max, round } from 'lodash';
import { formatCurrency } from 'src/utils/formatNumber';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { useMedspaPractitioners } from 'src/hooks/queries/usePractitioners';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { SelectList } from 'src/components/common/SelectList';
import { PROVIDER_FILTER_TITLE } from 'src/constants/clientLead.constants';
import { Skeleton } from '@material-ui/lab';
import Page from 'src/components/common/Page';
import { useStyles } from './reports.styles';
import { ProviderList } from './TableLeads';
import { IconLeftAccordionSummary } from '../OrderingTab/inventoryOrder.styles';

const ExpandIcon: React.FC<ExpandIconParams> = ({ open, setOpen }: ExpandIconParams): React.ReactElement => (
  <IconButton size="small" onClick={() => setOpen(!open)}>
    <IconCross open={open} />
  </IconButton>
);

const CreateCampaign: React.FC<ICreateCampaignProps> = ({ open, toggleView }): React.ReactElement => {
  const [campaignKey, setCampaignKey] = useState<string | undefined>();
  const [practitionerId, setPractitionerId] = useState<string | undefined>();
  const [campaignName, setCampaignName] = useState<string | undefined>();

  const { data: providerData, isLoading } = useMedspaPractitioners({}, true, () => 'v3/practitioners');

  const { mutateAsync: createCampaign } = useCreateAdCampaign();

  const inputValid = campaignName && practitionerId && campaignKey && campaignKey.indexOf(' ') < 0;

  const createNewCampaign = async (): Promise<void> => {
    if (inputValid) {
      await createCampaign({
        name: campaignName,
        practitionerId: +practitionerId,
        key: campaignKey,
      });

      toggleView();
    }
  };

  const classes = useStyles();

  const providerList = !providerData
    ? {}
    : Object.fromEntries(
      providerData.map((provider: IPractitioner) => [
        provider.id.toString(),
        `${provider.firstName} ${provider.lastName}`,
      ])
    );

  useEffect(() => {
    if (!practitionerId && providerData) {
      setPractitionerId(Object.keys(providerList)[0]);
    }
  }, [practitionerId, providerList]);

  return (
    <Slide direction="left" enter in={open} exit timeout={{ enter: 500, exit: 100 }}>
      <Paper className={classes.editInsert} variant="elevation" elevation={2}>
        <Typography variant="h4" className={classes.newCampaignTitle}>
          New Campaign
        </Typography>
        <Box padding={2}>
          <Box padding={2}>
            {isLoading ? (
              <Skeleton className={classes.channelSelectLarge} />
            ) : (
              <SelectList
                title={PROVIDER_FILTER_TITLE}
                listItems={providerList}
                selected={`${practitionerId}`}
                setSelected={(newVal: string | null) => {
                  setPractitionerId(newVal ?? undefined);
                }}
                className={classes.channelSelectLarge}
                loadSelected
              />
            )}
          </Box>
          <Box padding={2}>
            <InputLabel shrink htmlFor="name">
              Campaign Name
            </InputLabel>
            <Input
              value={campaignName}
              onChange={(event: React.ChangeEvent) => setCampaignName((event.currentTarget as HTMLInputElement)?.value)}
              className={classes.campaignInput}
              name="name"
            />
          </Box>
          <Box padding={2}>
            <InputLabel shrink htmlFor="name">
              Campaign Key
            </InputLabel>
            <Input
              value={campaignKey}
              onChange={(event: React.ChangeEvent) =>
                setCampaignKey((event.currentTarget as HTMLInputElement)?.value?.split(' ')?.join('_'))
              }
              className={classes.campaignInput}
              name="name"
            />
          </Box>
          <Button
            disabled={!inputValid}
            variant="outlined"
            className={classes.saveCampaignButton}
            onClick={createNewCampaign}
          >
            {' '}
            Save
          </Button>
          <Button variant="outlined" className={classes.saveCampaignButton} onClick={toggleView}>
            Cancel
          </Button>
        </Box>
      </Paper>
    </Slide>
  );
};

const RowItemCampaigns: React.FC<ICampaignRowProps> = ({ row, startExpanded }): React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const classes = useStyles();

  const cpc = !row.clicks ? 0 : round(row.spend / row.clicks, 2);

  const conversionValue = max([row.conversions, row.customerCount]) || 0;

  const cpv = !conversionValue ? 0 : round(row.spend / conversionValue, 2);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startExpanded && setExpanded(true);
  }, []);

  return (
    <Accordion className={classes.accordion} expanded={expanded}>
      <IconLeftAccordionSummary expandIcon={<ExpandIcon open={expanded} setOpen={setExpanded} />}>
        <Box className={classes.accordionRow}>
          <Box>{row.name.split('_').join(' ')}</Box>
          <Box>
            <Chip className={classes.longChip} color="primary" variant="outlined" label={row.practitioner} />
          </Box>
          <Box>
            <Chip color="primary" variant="outlined" label={row.marketingChannel} />
          </Box>
        </Box>
      </IconLeftAccordionSummary>
      <AccordionDetails>
        <Box className={classes.campaignDetails}>
          <Box>
            <Box>
              <Typography>Lifetime Spend:</Typography>
              <Typography>{formatCurrency(row.spend || 0)}</Typography>
            </Box>
            <Box>
              <Typography>Impressions:</Typography>
              <Typography>{row.impressions || 0}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography>Clicks:</Typography>
              <Typography>{row.clicks || 0}</Typography>
            </Box>
            <Box>
              <Typography>Cost per Click:</Typography>
              <Typography>{formatCurrency(cpc)}</Typography>
            </Box>
          </Box>
          <Box>
            <Box>
              <Typography>Total Leads:</Typography>
              <Typography>{row.clientLeadCount || 0}</Typography>
            </Box>
            <Box>
              <Typography>Cost per Conversion:</Typography>
              <Typography>{formatCurrency(cpv)}</Typography>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const TableCampaigns: React.FC = (): React.ReactElement => {
  const [practitionerId, setPractitionerId] = useState<string | undefined>();

  const { data: adCampaigns, isLoading } = useAdCampaigns({
    practitionerId: !practitionerId ? undefined : +practitionerId,
  });

  const classes = useStyles();

  return (
    <>
      <Box padding={2}>
        <Box padding={2}>
          <ProviderList
            selected={`${practitionerId}`}
            className={classes.channelSelect}
            setSelected={(newVal: string | null) => {
              setPractitionerId(newVal || undefined);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.noShadowShort}>
        {isLoading ? (
          <MultipleSkeleton />
        ) : (
          adCampaigns?.map(
            (row: IAdCampaign, id: number): React.ReactElement => (
              <RowItemCampaigns startExpanded={id === 0} key={`${row.id}${row.practitionerId}${row.name}`} row={row} />
            )
          )
        )}
      </Box>
    </>
  );
};

const LeadCampaigns: React.FC = (): React.ReactElement => {
  const [newCampaign, setNewCampaign] = useState<boolean>(false);
  const classes = useStyles();

  const toggleView = (): void => setNewCampaign(!newCampaign);

  return (
    <Page title="Ad Campaigns">
      <Box className={classes.reportsMain}>
        <Box className={classes.reportsHeader}>
          <Build className={classes.userIcon} />
          <Typography className={classes.adCampaignsTitle} variant="h3">
            Ad Campaigns
          </Typography>
          <Button variant="outlined" className={classes.newCampaignButton} onClick={toggleView} disabled={newCampaign}>
            New Campaign
          </Button>
        </Box>
        <Paper data-obscured={newCampaign} className={classes.flexColumns} variant="elevation" elevation={2}>
          <CreateCampaign open={newCampaign} toggleView={toggleView} />
          <TableCampaigns />
        </Paper>
      </Box>
    </Page>
  );
};

export { LeadCampaigns, LeadCampaigns as default };
