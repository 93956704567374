import { identity, pickBy } from 'lodash';
import { useQuery, useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { AD_CAMPAIGNS } from 'src/constants/reactQuery.keys';
import { IAdCampaign, IAdCampaignLookup } from 'src/interfaces/IAdCampaign';
import AdCampaign from 'src/services/AdCampaign';
import { handleQueryError } from 'src/utils/inventoryOrdering';

const useAdCampaigns = ({ disabled, ...apiParams }: IAdCampaignLookup): UseQueryResult<IAdCampaign[] | undefined> =>
  useQuery<IAdCampaign[] | undefined>({
    queryKey: [AD_CAMPAIGNS, apiParams.practitionerId, apiParams.page],
    queryFn: (): Promise<IAdCampaign[] | undefined> => AdCampaign.index(pickBy(apiParams, identity)),
    enabled: !disabled,
    onError: handleQueryError('fetching ad campaigns'),
  });

const useCreateAdCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adCampaign: Partial<IAdCampaign>): Promise<void> => {
      await AdCampaign.create(adCampaign);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
    onError: handleQueryError('creating ad campaign'),
  });
};

export { useCreateAdCampaign, useAdCampaigns, useAdCampaigns as default };
