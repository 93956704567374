import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'src/initializers/queryClient';
import { CreateResourceFilePayload, ResourceFiles } from 'src/services/resources/resourcesFiles';
import { useReactQueryOnErrorHandler } from 'src/utils/global';
import toastMessagesCompiler from 'src/utils/toastMessagesCompiler';

export const useResourcesFiles = (category: number) => {
  const response = useQuery(['RESOURCES_FILES', category], () => ResourceFiles.get(category), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onError: () =>
      useReactQueryOnErrorHandler(
        toastMessagesCompiler('generic.error_message', { action: 'fetching', element: 'resources' })
      ),
  });

  return {
    ...response,
    resources: response.data || [],
  };
};

export const useUpdateResourcesFile = () =>
  useMutation(({ id, data }: { id: number; data: CreateResourceFilePayload }) => ResourceFiles.update(id, data), {
    onError: () =>
      useReactQueryOnErrorHandler(
        toastMessagesCompiler('generic.error_message', { action: 'updating', element: 'resource' })
      ),
  });

export const useCreateResourcesFile = () =>
  useMutation(({ data }: { data: CreateResourceFilePayload }) => ResourceFiles.create(data), {
    onError: () =>
      useReactQueryOnErrorHandler(
        toastMessagesCompiler('generic.error_message', { action: 'creating', element: 'resource' })
      ),
  });

export const useDestroyResourcesFile = () =>
  useMutation((id: number) => ResourceFiles.delete(id), {
    onError: () =>
      useReactQueryOnErrorHandler(
        toastMessagesCompiler('generic.error_message', { action: 'deleting', element: 'resource' })
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('RESOURCES_FILES');
      // queryClient.invalidateQueries(['RESOURCES_FILES', formData.category]);
    },
  });
