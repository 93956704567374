import React, { useState } from 'react';
import { makeStyles, Box, Avatar, Divider, CircularProgress } from '@material-ui/core';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { EHRButton, EHRCRUDModal, EHRModal, EHRTextField, EHRTypography } from 'src/components/ui/v1';
import { ValidResourceInstance } from '@devexpress/dx-react-scheduler';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import moment from 'moment';
import { useMedspaAppointmentFullContext } from 'src/hooks/medspaCalendar/useAppointments';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { useDeleteMedspaBlockOffMutation } from 'src/hooks/medspaCalendar/useBlockOff';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { MedspaCalendarGFEStatusEnum } from 'src/constants/general.constants';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';
import { ExtendedAppointmentModel } from './grouping';
import { BookingTypeEnum } from './EnumBookingType';
import { AppointmentStatusEnum } from './AppointmentStatusEnum';
import { getGFEStatusIconComponent, getGFEStatusTooltipText } from './gfeStatusUtils';
import { MedspaCalendarAppointmentCancelModal } from './MedspaCalendarAppointmentCancelModal';
import { MedspaCalendarAppointmentConfirmModal } from './MedspaCalendarAppointmentManualConfirmationModal';
import AppointmentHistory from './AppointmentHistory';
import { AppointmentStatusTag } from '../appointmentAction';
import { REQUEST_STATUS_OPTIONS } from '../../../../constants/newPhotoRequest.constants';
import { PhotoRequested } from '../../../DashboardPractitioner/Tabs/RowItem';

const useStyles = makeStyles(() => ({
  patientNameBanner: {
    backgroundColor: '#F1F1F1',
    borderRadius: '0.625rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    justifyContent: 'space-between',
  },
  historyStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.25rem',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#D3D3D3',
    '&.completed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.confirmed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.rescheduled': {
      backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
      color: PENDING_TAG_FONT_COLOR,
    },
    '&.cancelled': {
      backgroundColor: '#FFE1E1',
      color: '#B83F3F',
    },
  },
  historyStatusIcon: {
    width: '1rem',
    height: '1rem',
  },
  label: {
    color: '#706F6F',
  },
}));

type TooltipContentProps = AppointmentTooltip.ContentProps & {
  appointmentResources?: ValidResourceInstance[];
  open: boolean;
  onClose: () => void;
  onOpenButtonClick?: () => void;
  providersMap: { [key: number]: IPractitioner };
  selectedMedspaLocation: IMedspaLocation | undefined;
};

const MedspaCalendarTooltipContent = (props: TooltipContentProps) => {
  const {
    appointmentData,
    formatDate,
    appointmentResources,
    open,
    onClose,
    onOpenButtonClick,
    providersMap,
    selectedMedspaLocation,
  } = props;
  const classes = useStyles();
  const appointment = appointmentData as ExtendedAppointmentModel;
  const resource = appointmentResources?.[0];

  const [action, setAction] = useState<string | null>(null);
  const { userGroupId } = useSelector(({ auth }: RootState) => auth);

  const { data: appointmentFullContext, isLoading: isFetchingFullContext } = useMedspaAppointmentFullContext(
    (appointment?.bookingType === BookingTypeEnum.APPOINTMENT && (appointment?.id as number)) || null
  );

  const { mutate: mutateDeleteBlockOffHour, isLoading: isDeletingBlockOffHour } = useDeleteMedspaBlockOffMutation(
    userGroupId,
    () => onClose()
  );

  if (!appointment) {
    return <></>;
  }

  if (!userGroupId) {
    return <>No MedspaId </>;
  }

  const blockOffDateRange = {
    start: new Date(appointment.startDate.toString()),
    end: new Date(appointment.endDate.toString()),
  };

  if (!resource) {
    return <></>;
  }

  if (!selectedMedspaLocation) {
    return <></>;
  }

  const isBlockOff = appointment.bookingType === BookingTypeEnum.BLOCK_OFF_HOUR;
  const provider = providersMap[resource.id as number];

  // Block Off related UIs

  if (isBlockOff) {
    const { name, address, city, state, zipCode } = selectedMedspaLocation as IMedspaLocation;

    const onDeleteBlockOff = () => {
      if (!appointmentData) {
        return;
      }

      mutateDeleteBlockOffHour(appointmentData.id as number);
    };
    const isMutatingBlockOff = isDeletingBlockOffHour;
    return (
      <EHRModal dataCy="modal-edit-block-off" open={open} onClose={onClose}>
        <EHRModal.Title dataCy="modal-edit-block-off-title">
          <EHRTypography dataCy="label-practitioner-name" variant="h5">
            Block Off Details
          </EHRTypography>
        </EHRModal.Title>

        <EHRModal.Body dataCy="modal-edit-block-off-body">
          <Box display="flex" flexDirection="column" width="100%" gridGap="2rem">
            <Box display="flex" flexDirection="row" gridGap="4.5rem">
              <Box flex={1}>
                <EHRTypography dataCy="label-location" variant="caption">
                  Location
                </EHRTypography>
                <Box>{name}</Box>
                <Box>
                  {address}, {city}, {state}, {zipCode}
                </Box>
              </Box>

              <Box flex={1}>
                <EHRTypography dataCy="label-provider" variant="caption">
                  Provider
                </EHRTypography>
                <Box display="flex" flexDirection="row" alignItems="center" gridGap="1rem">
                  <Box>
                    <Avatar src={provider.profileImageUrl} />
                  </Box>
                  <Box>
                    {provider.firstName} {provider.lastName}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" gridGap="4.5rem">
              <Box flex={1}>
                <EHRTypography dataCy="label-date-time" variant="caption">
                  Date & Time
                </EHRTypography>
                <Box
                  // @ts-ignore
                  style={{ textWrap: 'nowrap' }}
                >
                  {moment(blockOffDateRange.start).format('dddd, MMMM DD, YYYY')}
                </Box>
                <Box>
                  {moment(blockOffDateRange.start).format('hh:mm A')}
                  &nbsp;&mdash;&nbsp;
                  {moment(blockOffDateRange.end).format('hh:mm A')}
                </Box>
              </Box>

              <Box flex={1}>
                <EHRTypography dataCy="label-message" variant="caption">
                  Message
                </EHRTypography>
                <Box>
                  <pre style={{ margin: 0 }}>{appointment.notes}</pre>
                </Box>
              </Box>
            </Box>
          </Box>
        </EHRModal.Body>

        <EHRModal.Footer dataCy="modal-edit-block-off-footer">
          <EHRButton
            dataCy="btn-delete-block-hour"
            color="danger"
            variant="outlined"
            onClick={onDeleteBlockOff}
            text="Delete"
            disabled={isMutatingBlockOff}
          />

          <EHRButton
            dataCy="btn-save-block-hour"
            color="default"
            variant="outlined"
            onClick={() => {
              onOpenButtonClick?.();
              onClose();
            }}
            text="Edit"
          />
        </EHRModal.Footer>
      </EHRModal>
    );
  }

  // Appointment related UIs
  if (action === 'cancel') {
    return (
      <MedspaCalendarAppointmentCancelModal open onClose={onClose} setAction={setAction} appointment={appointment} />
    );
  }
  if (action === 'confirm') {
    return (
      <MedspaCalendarAppointmentConfirmModal open onClose={onClose} setAction={setAction} appointment={appointment} />
    );
  }

  const startDateFormatted = formatDate(appointmentData?.startDate, { hour: 'numeric', minute: 'numeric' });
  const endDateFormatted = formatDate(appointmentData?.endDate, { hour: 'numeric', minute: 'numeric' });

  const onViewProfileClick = () => {
    window.location.href = `/patient/${appointmentFullContext?.customer?.id}`;
  };

  const IconComponent = getGFEStatusIconComponent(appointment.gfeStatus as MedspaCalendarGFEStatusEnum);
  const hasPhotoRequested =
    appointmentFullContext?.customer?.lastPhotoRequestStatus === REQUEST_STATUS_OPTIONS.REQUESTED ||
    appointmentFullContext?.customer?.lastPhotoRequestStatus === REQUEST_STATUS_OPTIONS.REJECTED;

  return (
    <EHRCRUDModal dataCy="modal-appointment-details" open={open} onClose={onClose}>
      <EHRCRUDModal.Title dataCy="modal-appointment-details-title" title="Appointment Details" handleClose={onClose} />
      <EHRCRUDModal.Body dataCy="modal-appointment-details-body">
        <Box width="100%">
          <Box display="flex" flexDirection="row" marginBottom="0.5rem">
            <Box width="4.5rem">
              <Avatar style={{ width: '3rem', height: '3rem' }} />
            </Box>

            <Box className={classes.patientNameBanner}>
              <Box
                style={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                }}
              >
                {appointment.patientName}
              </Box>

              {appointmentFullContext && (
                <Box onClick={onViewProfileClick} style={{ cursor: 'pointer', color: '#12574D' }}>
                  View Profile
                </Box>
              )}
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" width="100%" marginBottom="2rem">
            <Box width="4.5rem" />
            <Box display="flex" flexDirection="column" width="100%" gridGap="2rem">
              <Box display="flex" flexDirection="column">
                <Box>{moment(appointment.startDate).format('dddd, MMMM DD, YYYY')}</Box>
                <Box>
                  {startDateFormatted}&nbsp;&mdash;&nbsp;{endDateFormatted}
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box marginBottom="0.25rem" className={classes.label}>
                  Provider
                </Box>
                <Box display="flex" gridGap="0.75rem">
                  <Box>
                    <Avatar src={provider.profileImageUrl} />
                  </Box>
                  <Box display="flex" alignItems="center">
                    {provider.firstName} {provider.lastName}
                  </Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <Box className={classes.label}>Appointment Type:</Box>
                <Box>{appointment.appointmentTypeName}</Box>
              </Box>

              {appointmentFullContext?.serviceGroups?.length > 0 && (
                <Box display="flex" flexDirection="column">
                  <Box className={classes.label}>Service Groups:</Box>
                  <Box>{appointmentFullContext?.serviceGroups.map((serviceGroup) => serviceGroup.name).join(', ')}</Box>
                </Box>
              )}

              {appointmentFullContext?.services?.length > 0 && (
                <Box display="flex" flexDirection="column">
                  <Box className={classes.label}>Services:</Box>
                  <Box>{appointmentFullContext?.services.map((service) => service.name).join(', ')}</Box>
                </Box>
              )}

              {appointmentFullContext?.addOns?.length > 0 && (
                <Box display="flex" flexDirection="column">
                  <Box className={classes.label}>Extra Time:</Box>
                  <Box>{appointmentFullContext?.addOns.map((addon) => addon.name).join(', ')}</Box>
                </Box>
              )}
              <Box display="flex">
                {!isFetchingFullContext && appointmentFullContext?.appointment && (
                  <AppointmentStatusTag action={appointmentFullContext.appointment.status} />
                )}
              </Box>
            </Box>
          </Box>

          <Divider />

          <h3 style={{ margin: '2rem 0' }}>Client Details</h3>

          <Box display="flex" flexDirection="column" width="100%" gridGap="2rem" marginBottom="2rem">
            <Box display="flex" flexDirection="row" width="100%">
              <Box flex={1}>
                <EHRTextField dataCy="input-patientEmail" label="Email" value={appointment.patientName} readOnly />
              </Box>

              <Box flex={1}>
                <EHRTextField
                  dataCy="input-patientPhone"
                  label="Phone"
                  readOnly
                  value={appointmentFullContext?.customer?.phone}
                />
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" width="100%">
              <Box flex={1}>
                <EHRTypography dataCy="label-for-gfeStatys" variant="label">
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    GFE Status
                  </Box>
                </EHRTypography>

                <EHRTypography dataCy="readOnly-gfeStatus" variant="readonly">
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    <IconComponent /> {getGFEStatusTooltipText(appointment.gfeStatus)}
                    {hasPhotoRequested && (
                      <Box>
                        <PhotoRequested />
                      </Box>
                    )}
                  </Box>
                </EHRTypography>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row" width="100%">
              <Box flex={1}>
                <EHRTypography dataCy="label-for-gfeStatys" variant="label">
                  <Box display="flex" gridGap="0.25rem" alignItems="center">
                    Notes
                  </Box>
                </EHRTypography>

                <EHRTypography dataCy="readOnly-gfeStatus" variant="readonly">
                  {appointmentFullContext?.appointment?.notes}
                </EHRTypography>
              </Box>
            </Box>
          </Box>

          <Divider />

          <h3 style={{ margin: '2rem 0' }}>History</h3>

          {isFetchingFullContext && <CircularProgress />}
          {!isFetchingFullContext &&
            appointmentFullContext?.history.map((history, index) => (
              <Box paddingY="1.25rem" style={{ ...(index > 0 && { borderTop: '1px solid #D3D3D3' }) }}>
                <Box display="flex" justifyContent="space-between" marginBottom="0.5rem" lineHeight="1.8rem">
                  <Box fontWeight="bold" color="#595a5e">
                    {history.name || '-'}
                  </Box>
                  <Box>
                    <AppointmentStatusTag action={history.status as AppointmentStatusEnum} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  {!!history.notes && <Box className={classes.label}>Note: {history.notes}</Box>}
                  <Box>{moment(history.datetime).format('MMM D, YYYY [at] h:mm A')}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <AppointmentHistory appointmentId={history.id} />
                </Box>
              </Box>
            ))}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-appointment-details-footer">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <EHRButton
              dataCy="btn-cancel-appointment"
              color="danger"
              variant="outlined"
              disabled={appointment.status === AppointmentStatusEnum.CANCELLED}
              onClick={() => {
                setAction('cancel');
              }}
              text="Cancel Appointment"
            />
          </Box>
          <Box>
            <EHRButton
              dataCy="btn-confirm-appointment"
              color="primary"
              variant="outlined"
              disabled={
                !!appointment.confirmedAt ||
                appointment.status !== AppointmentStatusEnum.PENDING_CONFIRMATION ||
                moment(appointment.startDate).isBefore(moment())
              }
              onClick={() => {
                setAction('confirm');
              }}
              text="Confirm appointment"
            />
          </Box>
          <Box display="flex" alignItems="center" gridGap="1rem">
            <EHRButton
              dataCy="btn-reschedule-appointment"
              color="default"
              variant="outlined"
              disabled={
                appointment.status === AppointmentStatusEnum.CANCELLED ||
                moment(appointment.startDate).isBefore(moment())
              }
              onClick={() => {
                onClose();
                onOpenButtonClick?.();
              }}
              text="Reschedule"
            />
          </Box>
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};

interface MedspaCalendarAppointmentTooltipProps {
  providersMap: { [key: number]: IPractitioner };
  selectedMedspaLocation: IMedspaLocation | undefined;
}

const MedspaCalendarAppointmentTooltip = ({
  providersMap,
  selectedMedspaLocation,
}: MedspaCalendarAppointmentTooltipProps) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <AppointmentTooltip
      visible={open}
      onVisibilityChange={(visible: boolean) => {
        setOpen(visible);
      }}
      layoutComponent={(layoutComponentProps) => {
        const {
          appointmentResources,
          appointmentMeta,
          formatDate,
          recurringIconComponent,
          onOpenButtonClick,
          ...restProps
        } = layoutComponentProps;
        return (
          <MedspaCalendarTooltipContent
            {...restProps}
            formatDate={formatDate}
            recurringIconComponent={recurringIconComponent}
            appointmentData={appointmentMeta?.data}
            appointmentResources={appointmentResources}
            open={open}
            onClose={() => setOpen(false)}
            onOpenButtonClick={onOpenButtonClick}
            providersMap={providersMap}
            selectedMedspaLocation={selectedMedspaLocation}
          />
        );
      }}
    />
  );
};

export default MedspaCalendarAppointmentTooltip;
